@use '@styles/colors.module.scss';
@use '@styles/borders.module.scss';
@use '@styles/shadows.module.scss';
@use '@styles/indents.module.scss';
@import '@styles/mixins.scss';

.formWrapper {
  position: relative;
  padding: 20px;
  border-radius: borders.$radius-m;
  box-shadow: shadows.$page-content !important;
  background-color: colors.$white;
  margin-bottom: indents.$indent-m;
  overflow: hidden;
}

.logBtnWrapper {
  @include breakpoint(desktop) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.submitBtnWrapper {
  display: flex;
  gap: 12px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: colors.$black;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.submitBtn {
  margin-right: indents.$indent-xs;
}

.sendBtn {
  &:global(.MuiButton-root) {
    background-color: transparent;
    color: colors.$red;
    border: 2px solid transparent;

    &:hover {
      color: colors.$red;
      border-color: colors.$red;
    }
  }
}


.delBtn {
  &:global(.MuiButton-root) {
    background-color: transparent;
    color: colors.$almost-white;
    border: 2px solid transparent;

    &:hover {
      color: colors.$almost-white;
      border-color: colors.$almost-white;
    }
  }
}

.submitBtnIcon {
  color: colors.$red;
}

.submitDelIcon {
  color: colors.$almost-white;
}

.closeModalBtn {
  &:global(.MuiButton-root) {
    margin-top: 15px;
  }
}

.loadingIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.imagePreviewWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 304 / 168;
  gap: 20px;
  background: colors.$background;
  border: 1px solid transparent;
  border-radius: borders.$radius-m;
  color: colors.$grey;
  overflow: hidden;
}

.imagePreview {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
